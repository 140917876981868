import React from 'react';
import "../../css/splash.css"

const Suggest = ({ data }) => {
    return (
        <a href={data["smart_link"]}>
            <div className="Glass" style={{ marginTop: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: "10px", maxWidth: "450px" }}>
                <div className="thumb">
                    <img src={data.metadata.image} />
                </div>

                <div style={{ color: "white", padding: "15px", fontWeight: "700", wordWrap: "break-word", maxWidth: "500px" }}>
                    {data.metadata.title}
                </div>
            </div>
        </a>
    );
};

export default Suggest;
