import React from 'react';
import "../../css/splash.css"

const Blog = ({ data }) => {
    const blogLink = `/blog/${data["id"]}`
    return (
        <a href={blogLink}>
            <div className="Glass" style={{ marginTop: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: "10px", maxWidth: "450px" }}>
                <div className="thumb">
                    <img src={data.image} />
                </div>

                <div style={{ color: "white", padding: "15px", fontWeight: "700", wordWrap: "break-word", maxWidth: "500px" }}>
                    {data.title}
                </div>
            </div>
        </a>
    );
};

export default Blog;
